import React from 'react';
import {ReactComponent as X} from "../../icons/x-lg.svg";

const Pattern = ({addOrderForm, setAddOrderForm, index}) => {
    function update(param, value) {
        addOrderForm.patterns[index][param] = value
        setAddOrderForm({...addOrderForm})
    }
    function removePattern(pattern_index){
        addOrderForm.patterns = addOrderForm.patterns.filter((pattern, index) => index !== pattern_index)
        setAddOrderForm({...addOrderForm})
    }

    return (
        <div className=' input-group mb-2'>
            <input type="number" min='1' max='12' value={addOrderForm.patterns[index].number } onChange={(e) => {
                update('number', e.target.value)
            }} placeholder="Номер шаблона" className="col-sm-2 p-2 form-control"/>
            <input type="number" value={addOrderForm.patterns[index].count} onChange={(e) => {
                update('count', e.target.value)
            }} placeholder="Кол-во" className="col-sm-2 p-2 form-control" max='99' min='1' />
            <input type="text" value={addOrderForm.patterns[index].code} onChange={(e) => {
                update('code', e.target.value)
            }} placeholder="Мешность" className="col-sm-7 p-2 form-control" />
            <button onClick={(e)=>{removePattern(index)}} className='col-sm-1 btn btn-outline-danger 'type="button">
                <X/>
            </button>
        </div>
    )
}
export default Pattern;