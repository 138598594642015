import React, {useContext} from 'react';
import Procedure from "./Procedure";
import apiUrl, {statuses} from "../../../settings/config";
import {convert_date, getAuthCookie} from "../../../settings/modules";
import {authContext} from "../../Context";
import axios from "axios";
const Procedures = ({procedures,setOrderInfo,order_info,procedure_list}) => {

    const {User} = useContext(authContext);

    function setNextStatus(pattern_id){
        procedures.map(procedure => {

            const status_index = statuses.indexOf(procedure.status)
            if (procedure.id === pattern_id && status_index !== statuses.length-1) {

                procedure.status = statuses[status_index+1]
                axios.post(apiUrl+'/order', {'pattern_id':pattern_id, 'order_id':order_info.id,'status_id':status_index+1, 'type':'embossing_procedures'}, getAuthCookie());

                switch (procedure.status){
                    case 'active':
                        procedure.time_active = convert_date(new Date()/1000)
                        procedure.worker_active = `${User.last_name} ${User.first_name[0]}. ${User.father_name[0]}.`
                        break
                    case 'closed':
                        procedure.time_close = convert_date(new Date()/1000)
                        procedure.closed_worker = `${User.last_name} ${User.first_name[0]}. ${User.father_name[0]}.`
                        break
                    default:
                }
            }
        })
        setOrderInfo({...order_info})
    }
    return (
        <tbody>
            {procedures.map((procedure, index) => <Procedure key={index} procedure={procedure}
                                                             setNextStatus={setNextStatus} procedure_list={procedure_list}
                                                             setOrderInfo={setOrderInfo}/>)}
        </tbody>
    )
}
export default Procedures;