import axios from "axios";
import {Route, Routes} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import {routes} from "../settings/config";

import Navbar from "./Page/Navbar";
import LoadingPage from './LoadingPage/LoadingPage';

import {authContext} from "./Context";
import apiUrl from "../settings/config";
import {getAuthCookie, isAuth} from "../settings/modules";
import Login from "./Login/Login";
import Notification from "./Notifications/Notifications";


function App() {
    console.log('APP START')

    const [User, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios.get(apiUrl + '/auth/me', getAuthCookie())
            .then((resp) => {
                setIsLoading(false)
                if (resp.data?.resultCode === 2) {
                    console.log('clear token')
                    return window.localStorage.removeItem('token')
                }

                setUser(resp.data)
            })
    }, [])

    if (isLoading) {
        return <LoadingPage/>
    }
    return (
        <div>

            <authContext.Provider value={{
                User, setUser
            }}>
                <Navbar/>
                <div className="container-fluid my-3">
                    <Routes>
                        {Object.keys(User).length > 0 ? '' : <Route exact path='*' element={<Login/>}/>}
                        {routes.map((route, index) => {
                            if (isAuth(User, route)) {
                                return <Route key={index} exact path={route.link+(route.link_params?.length > 0 ? '/:' + route.link_params.join('/:'): '' )} element={route.comp}/>
                            }
                        })}
                    </Routes>
                </div>
            </authContext.Provider>

        </div>
    )
}


export default App;
