import React from "react";
import {cfg_color, cfg_statuses} from '../../../settings/config';
import {EmbossingCardStatus} from "../../../settings/modules";
import {NavLink} from "react-router-dom";

const Card = ({shaft}) => {
    const status = EmbossingCardStatus(shaft)

    return (<div className={"card m-auto my-3"} style={{maxWidth:'900px'}}>
        <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">Заказ №{shaft.code} {shaft.number}</h5>
            <h6 className='p-2' style={{ backgroundColor: cfg_color[status], color: '#fff', borderRadius: '10px' }}>{cfg_statuses[status]}</h6>

        </div>
        <div className="card-body">

            {shaft.description && <h6 className="card-text my-3">Описание: {shaft.description}</h6>}
            <hr/>
            <NavLink style={{textDecoration: "none", color: '#000', width: '100%'}}
                     role="button"
                     className=" h5"
                     to={`/embossing_shafts/${shaft.id}`}>Подробная информация</NavLink>
            <hr/>
            <div className="" id={`pattern-${shaft.id}`}>
                <div className='my-4'>
                    <table >
                        <tbody>
                        <tr>
                            <td>Операций выполнено:</td>
                            <td style={{paddingLeft:'1rem'}}>{shaft.completed}/{shaft.count}</td>
                        </tr>
                        <tr>
                            <td>Активная операция:</td>
                            {
                                shaft.active_state ?  <td style={{paddingLeft:'1rem'}}>Поставил <b>{shaft.active_state.worker}</b> на <b>{shaft.active_state.name}</b> c <b>{shaft.active_state.start}</b> до <b>~ {shaft.active_state.end}</b></td>:
                                    <td style={{paddingLeft:'1rem'}}><b>Нет активных операций</b></td>
                            }
                        </tr>
                        <tr>
                            <td>Сделать до:</td>
                            <td style={{paddingLeft:'1rem'}}>{shaft.time_deadline}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <p>Создал <b>{shaft.author}</b> в <b>{shaft.time_start}</b></p>
        </div>
    </div>)
}

export default Card;