import Orders from '../components/Orders/Orders'
import Login from '../components/Login/Login'
import AddOrder from '../components/addOrder/AddOrder';
import Order from '../components/Order/Order';
import Shafts from '../components/Shafts/Shafts';
import EmbossingShafts from '../components/Embossing/Shafts/EmbossingShafts';
import EmbossingShaft from '../components/Embossing/Info/EmbossingShaft';
import AddEmbossing from "../components/Embossing/Add/AddEmbossing";

// const apiUrl = 'http://localhost:3003';
const apiUrl = 'https://suegravo.api.ginda.info';

export default apiUrl;

export const routes = [
    {comp:<Orders/>,link:'/', component: 'tasks', name: 'Шаблоны', roles: [1,2,3,4], navbar: true},
    {comp:<Login/>,link:'/login',component: 'login', name: 'Войти', roles: []},
    {comp:<AddOrder/>,link:'/add_order',component: 'add_order', name: 'Добавить заказ шаблон', roles: [1,2], navbar: true},
    {comp:<Shafts/>,link:'/shafts',component: 'shafts', name: 'Валы заказчиков', roles: [1,2,3,4], navbar: true},
    {comp:<Order/>,link_params:['id'],link:'/order',component: 'order', name: 'Информация о заказе', roles: [1,2,3,4]},
    {comp:<EmbossingShafts/>,link:'/embossing_shafts',component: 'embossing_shafts', name: 'Валы на тиснение', roles: [1,2,3,4], navbar: true},
    {comp:<EmbossingShaft/>,link:'/embossing_shafts/:id',component: 'embossing_shaft', name: 'Информация о вале', roles: [1,2,3,4]},
    {comp:<AddEmbossing/>,link:'/add_embossing',component: 'embossing_shaft', name: 'Добавить заказ на теснение', roles: [1,2], navbar: true}
]

export const cfg_titles = {
    'new': 'Взять в работу',
    'active': 'Завершить работу',
    'closed': 'Завершён',
    'canceled':'Отменить'
}

export const cards = [
    ['Объём', 'circumference'],
    ['Ширина гравюры', 'wight_engraving'],
    ['Ширина наложения', 'wight_overlay'],
    ['Сделать до', 'time_deadline'],
    ['Дизайн номер', 'pattern_number'],
]

export const cfg_styles = {
    'new': '',
    'active': 'warning',
    'closed': 'success',
    'canceled':'danger'
}
export const cfg_color = {
    'new':'#0721c9',
    'active':'#ffc107',
    'closed':'#198754',
    'danger':''
}
export const cfg_statuses = {
    'new': 'Новый',
    'active': 'В работе',
    'closed': 'Завершён',
    'canceled':'Отменён'
}
export const resultCodes = ['success', 'warning', 'danger', 'danger']

export const statuses = ['new', 'active', 'closed', 'canceled']