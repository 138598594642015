import React, {useContext} from 'react';
import Notification from "./Notification";
import {notifyContext} from "../Context";

const Notifications = () => {
    const {notifications, setNotifications} = useContext(notifyContext)
    return (
        <div aria-live="polite" aria-atomic="true" className="position-relative">
            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                {notifications.map((notify, index) => {
                    return <Notification key={index} notify={notify} setNotify={setNotifications}/>
                })}
            </div>
        </div>
    )
}
export default Notifications;