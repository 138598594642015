import React, {useEffect} from "react";
import {resultCodes} from "../../settings/config";

const Notification = ({notify, setNotify}) => {

    useEffect(()=>{
        setTimeout(removeNotify,15000)
    },[])

    if (!notify.message) return

    function removeNotify(){
        setNotify(prevs => prevs.filter(prev => prev !== notify))
    }

    return (
        <div className={`toast align-items-center text-white bg-${resultCodes[notify.resultCode]} border-0 d-block mb-1`} role="alert" aria-live="assertive"
             aria-atomic="true">
            <div className="d-flex">
                <div className="toast-body">
                    {notify.message}
                </div>
                <button type="button" className="btn-close btn-close-white me-1 m-auto" data-bs-dismiss="toast"
                        aria-label="Close" onClick={removeNotify}/>
            </div>
        </div>

    )
}
export default Notification;