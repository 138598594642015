import React, {useState, useEffect} from 'react';
import ShaftForm from "./ShaftForm";
import axios from "axios";
import {getAuthCookie} from "../../settings/modules";
import apiUrl from "../../settings/config";
import {notifyContext} from '../Context';
import Notifications from "../Notifications/Notifications";

const Shafts = () => {

    const [shafts, setShafts] = useState([])
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        axios.get(apiUrl + '/shafts/companies', getAuthCookie()).then((resp) => {
            setShafts(resp.data)
        })
    }, [])
    return (

        <div className='m-auto' style={{maxWidth:'800px'}}>
            <notifyContext.Provider value={{
                notifications, setNotifications
            }}>
                <Notifications/>
                <h1>Список валов заказчиков</h1>
                <table className='table'>
                    <thead>
                    <tr>
                        <th>Компания</th>
                        <th>Готовы</th>
                        <th>В работе</th>
                        <th>В хроме</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {shafts.map((shaft, index) =>
                        <ShaftForm key={index} shaft={shaft} setShafts={setShafts}/>
                    )}
                    </tbody>
                </table>
                </notifyContext.Provider>
        </div>

)
}
export default Shafts;