import React from "react";
import {ReactComponent as X} from "../../../icons/x-lg.svg";

const Procedure = ({setForm, procedure_types, procedure, index}) => {
    function editProcedure ({target:{value}}){
        setForm(form => {
            return {
                ...form,
                procedures: [...form.procedures.map((proc,i) => {
                    if (i === index) {
                        return {
                            ...proc,
                            'procedure_id': parseInt(value)
                        }
                    }
                    return proc
                })]
            }
        })
    }
    function procedureDelete() {
        setForm(prev => {
            return {
                ...prev,
                procedures: [...prev.procedures.filter(obj => obj !== procedure)]
            }
        })
    }

    return (
        <tr>
            <td>{index+1}</td>
            <td>
                <select onChange={editProcedure} className='form-select' name='procedure' value={procedure.id}>
                    {procedure_types.map((obj, index) => <option key={index} value={obj.id}>{obj.name}</option>)}
                </select>
            </td>
            <td className='text-center'>
                <button onClick={procedureDelete} className='btn btn-outline-danger'>
                    <X/>
                </button>
            </td>
        </tr>
    )
}
export default Procedure;