import axios from "axios";
import React, {useState, useContext} from "react";

import {authContext, notifyContext} from "../Context";
import EditButton from "../Page/EditButton";
import {ReactComponent as Save} from "../../icons/save.svg";

import apiUrl from "../../settings/config";
import {getAuthCookie} from "../../settings/modules";

const ShaftForm = ({shaft, setShafts}) => {
    const [isEdit, setEdit] = useState(false)
    const {setNotifications} = useContext(notifyContext);
    const {User} = useContext(authContext);

    function saveData() {
        setEdit(false)

        const body = {
            id: shaft.company.id,
            new: shaft.new,
            active: shaft.active,
            completed: shaft.completed
        }

        axios.put(apiUrl + '/shafts/companies', body, getAuthCookie()).then(resp => {
            setNotifications(prevs => [...prevs,{...resp.data}])
        })
    }

    function handleForm({target: {name, value}}) {
        setShafts(shafts =>
            shafts.map(obj => {
                if (obj === shaft) {
                    return {
                        ...obj,
                        [name]: parseInt(value)
                    }
                }
                return obj
            })
        )
    }

    if (isEdit) {
        return (
            <tr>
                <td>{shaft.company.name}</td>
                <td><input className='form-control' onBlur={handleForm} name='completed' type="number" min={0}
                           defaultValue={shaft.completed}/></td>
                <td><input className='form-control' onBlur={handleForm} name='active' type="number" min={0}
                           defaultValue={shaft.active}/></td>
                <td><input className='form-control' onBlur={handleForm} name='new' type="number" min={0}
                           defaultValue={shaft.new}/></td>
                <td className='text-center'>
                    <button className='btn btn-outline-primary' onClick={saveData}>
                        <Save/>
                    </button>
                </td>
            </tr>
        )
    }
    return (
        <tr>
            <td>{shaft.company.name}</td>
            <td>{shaft.completed}</td>
            <td>{shaft.active}</td>
            <td>{shaft.new}</td>
            <td className='text-center'>{ [3,4].includes(User.role_id) ? '' : <EditButton editHandle={() => setEdit((isEdit => !isEdit))}/>}</td>
        </tr>
    )
}
export default ShaftForm;