import React, {useEffect, useState} from 'react';
import axios from "axios";
import apiUrl from "../../../settings/config";
import {getAuthCookie,generate_key,$} from "../../../settings/modules";
import Procedure from "./Procedure";

const AddEmbossing = () => {

    const [companies, setCompanies] = useState([])
    const [procedures, setProcedures] = useState([])
    const [form, setForm] = useState({
        'company_id': '',
        'procedures':[],
        'number':'',
        'description':'',
        'time_deadline': ''
    })

    useEffect(() => {
        axios.get(apiUrl+'/get/companies',getAuthCookie()).then(resp => setCompanies(resp.data))
        axios.get(apiUrl+'/get/procedures',getAuthCookie()).then(resp => setProcedures(resp.data))
    }, [])

    function formHandler ({target:{name, value}}) {
        setForm({
            ...form,
            [name]: !['description','time_deadline'].includes(name) ? parseInt(value) : value
        })
    }

    function addProcedure () {
        setForm({
            ...form,
            procedures:[...form.procedures, {'procedure_id':procedures[0]?.id, key:generate_key('proc')}]
        })
    }

    function addTask () {
        if (form.company_id === '' || form.number === '' || form.procedures.length === 0) {
            alert('Вы должны заполнить все поля');
            return
        }
        axios.put(apiUrl+'/embossing/', form, getAuthCookie()).then(resp => {

            const form_model = {
                'company_id': '',
                'procedures':[],
                'number':'',
                'description':'',
                'time_deadline': ''
            }

            Object.keys(form_model).map(key => {
                if (form_model[key] !== '') return key
                document.getElementsByName(key)[ key !== 'description' ? 0 : 1].value = ''
            })

            setForm(form_model)
            alert(resp.data.message)
        })
    }

    return (
        <div className='container' style={{maxWidth:'800px'}}>
            <h1>Добавить заказ на теснение</h1>
            <div>
                <div className="mb-3">
                    <label className="form-label h5">Номер заказа:</label>
                    <div className='input-group mb-2'>
                        <select defaultValue={form.company_id} className='form-select' name='company_id' onChange={formHandler}>
                            <option value=''>Выберите заказчика</option>
                            {companies.map((company, index) => {
                                return (
                                    <option key={index} value={company.id}>{company.name} ({company.code})</option>
                                )
                            })}
                        </select>
                        <input onBlur={formHandler} type="number" name='number' className="form-control"
                               defaultValue={form.number}
                               placeholder='Номер'/>
                    </div>
                </div>
                <div>
                    <hr/>
                    Описание:
                      <textarea className='form-control' name='description' onBlur={formHandler} defaultValue={form.description}/>
                    <hr/>
                    Сделать до:
                    <input type="datetime-local" className='form-control' name='time_deadline' defaultValue={form.time_deadline} onBlur={formHandler}/>
                    <hr/>
                </div>
                <button className='btn btn-outline-primary' onClick={addProcedure}>Добавить операцию</button>
                <table className='table my-3'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Операции</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {form.procedures.map((obj, index) => <Procedure key={obj.key} procedure={obj} index={index} setForm={setForm} procedure_types={procedures}/>)}
                    </tbody>
                </table>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-success' onClick={addTask}>Добавить</button>
                </div>
            </div>
        </div>


    )
}
export default AddEmbossing;