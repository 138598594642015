import React, {useEffect, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {authContext} from "../Context";
import axios from "axios";
import apiUrl, {cfg_titles, cards, cfg_statuses,statuses,cfg_styles} from "../../settings/config";
import EditButton from "../Page/EditButton";
import {getAuthCookie, convert_date} from "../../settings/modules";

const Order = () => {
    console.log('render order')
    const order_id = useParams().id;
    const {User} = useContext(authContext);
    const [order_info, setOrderInfo] = useState({})


    function editHandle() {
        alert('Функция редактирования в разработке!')
    }
    function setNextStatus(pattern_id){

        order_info.patterns.map(pattern => {
            const status_index = statuses.indexOf(pattern.status)
            if (pattern.id === pattern_id && status_index !== statuses.length-1) {

                pattern.status = statuses[status_index+1]
                axios.post(apiUrl+'/order', {'pattern_id':pattern_id, 'order_id':order_info.id,'status_id':status_index+1, 'type':'patterns'}, getAuthCookie());

                switch (pattern.status){
                    case 'active':
                        pattern.time_active = convert_date(new Date()/1000)
                        pattern.worker_active = `${User.last_name} ${User.first_name[0]}. ${User.father_name[0]}.`
                        break
                    case 'closed':
                        pattern.time_close = convert_date(new Date()/1000)
                        pattern.closed_worker = `${User.last_name} ${User.first_name[0]}. ${User.father_name[0]}.`
                        break
                    default:
                }
            }
        })
        setOrderInfo({...order_info})
    }

    useEffect(() => {

        axios.get(apiUrl + '/order/' + order_id, getAuthCookie()).then((resp) => {
            setOrderInfo(resp.data)
        })
    }, [])


    return (
        <div>
            {
                Object.keys(order_info).length === 0 ? '' : (
                    <div>
                        <div className='mt-4 h2 d-flex justify-content-between'>
                            <div>
                            Информация о заказе <span className='px-1' style={{
                            backgroundColor: '#212529',
                            color: '#fff',
                            borderRadius: '10px'
                        }}>{order_info.code} {order_info.local_pattern_number} </span> <span>&nbsp;{order_info.is_repit?' Повтор' : ''}</span>:</div>
                            {[3,4].includes(User.role_id) ? '' :
                                <div className='mx-1'>
                                    <EditButton editHandle={editHandle}/>
                                </div>
                            }


                        </div>

                        <table className="table">
                            <thead>
                            <tr>
                                <th>№ Шаблона</th>
                                <th>Мешность</th>
                                <th>Гравёр взял</th>
                                <th>Взято в работу</th>
                                <th>Гравёр завершил</th>
                                <th>Завершено</th>
                                <th>Статус</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {order_info.patterns.map((pattern, index) => {
                                return (
                                    <tr key={index} className={`table-${cfg_styles[pattern.status]}`}>
                                        <td>{pattern.number_shaft}</td>
                                        <td>{pattern.name}</td>
                                        <td>{pattern.worker_active}</td>
                                        <td>{pattern.time_active}</td>
                                        <td>{pattern.closed_worker}</td>
                                        <td>{pattern.time_close}</td>
                                        <td>{cfg_statuses[pattern.status]}</td>
                                        <td>
                                            <button className='btn btn-primary ' disabled={pattern.status === 'closed' || User.role_id === 4}
                                                    onClick={() => setNextStatus(pattern.id)}>{cfg_titles[pattern.status]}</button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>


                        <h2 className='mt-4'>Дополнительная информация</h2>
                        <div className="row h5 my-3">
                            {cards.map((data, index) => {
                                return (<div className="col" key={index}>
                                    <div className="card mx-auto m-2"
                                         style={{minWidth: '300px', minHeight:'90px', boxShadow: '0px 10px 40px rgb(0 0 0 / 25%)'}}>
                                        <div className="card-body">
                                            <h5 className="card-title">{data[0]}: {order_info[data[1]]}</h5>
                                        </div>
                                    </div>
                                </div>)
                            })}

                        </div>
                    </div>)}

        </div>
    )
}
export default Order;