export function convert_date(timestamp) {
    if (!timestamp) return undefined
    let date = new Date(timestamp * 1000)
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    let days = date.getDate().toString()
    let months = (date.getMonth() + 1).toString()
    return `${hours.length > 1 ? hours : '0' + hours}:${minutes.length > 1 ? minutes : '0' + minutes} ${days.length > 1 ? days : '0' + days}.${months.length > 1 ? months : '0' + months}.${date.getFullYear()}`
}

export function $(id){
    return document.getElementById(id)
}
export function isAuth(User, route) {
    return User.is_active && route?.roles.includes(User.role_id)
}
export const getAuthCookie = () => {
    const token = localStorage.getItem('token');

    return {
        headers: {"Authorization": token}
    }
}

export const orderStatus = (order) => {
    if (order.closed_count === order.patterns_count){
        return 'closed'
    } else if (order.closed_count > 0 || order.active_count > 0) {
       return 'active'
    }
    return 'new'
}

export const EmbossingCardStatus = (shaft) => {
    if (shaft.completed === shaft.count) return 'closed'
    else if (shaft.active > 0 || shaft.completed > 0) return 'active'
    return 'new'
}


export const generate_key = (prev) => {
    return `${prev}_${new Date().getTime()}`
}
