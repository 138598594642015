import React, {useContext} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {authContext} from "../Context";
import {routes} from "../../settings/config";

const Navbar = () => {
    const {User, setUser} = useContext(authContext);
    const nav = useNavigate();

    return (<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" href="#">IT | Suegravo</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                            {routes.map((route, index) => {
                                if (!route.navbar || !route.roles.includes(User.role_id)) return null;
                                return (<li key={index} className="nav-item">
                                    <NavLink className="nav-link" to={route.link}>{route.name}</NavLink>
                                </li>)
                            })}
                        </ul>

                        {Object.keys(User).length && <button className="btn btn-outline-light" onClick={() => {
                            setUser({})
                            window.localStorage.removeItem('token')
                            nav('/login')
                        }}>Выйти</button>}

                    </div>
                </div>
             </nav>)
}


export default Navbar;