import axios from "axios";
import React, {useContext, useState} from "react";
import {useNavigate} from 'react-router-dom';

import {authContext, notifyContext} from "../Context";
import apiUrl from "../../settings/config";
import Notification from '../Notifications/Notifications';

const Login = () => {

    const {User, setUser} = useContext(authContext);
    const [notifications, setNotifications] = useState([]);
    const nav = useNavigate();

    if (Object.keys(User).length > 0) return nav('/');

    function Submit() {
        const login = document.getElementById('login').value;
        const password = document.getElementById('password').value;

        const body = {
            'login': login,
            'password': password
        }

        axios.post(apiUrl + '/auth', body)
            .then((resp) => {
                const data = resp.data;
                if (data.resultCode) {
                    return setNotifications(prevs => [...prevs,{
                        message: data.message,
                        resultCode: data.resultCode
                    }])
                }

                window.localStorage.setItem('token', data?.token);
                setUser(data);
                return nav('/');
            })
    }

    return (
        <div>
            <notifyContext.Provider value={{
                notifications, setNotifications
            }}>
                <Notification/>
                <div style={{width: '450px'}}
                     className="text-center position-absolute top-50 start-50 translate-middle">
                    <div className="my-5">
                        <h3>Авторизация</h3>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="login" className="form-label">Логин</label>
                        <input type="login" className="form-control" id="login"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Пароль</label>
                        <input type="password" className="form-control" id="password"/>
                    </div>
                    <button onClick={() => Submit()} className="btn btn-primary">Войти</button>
                </div>
            </notifyContext.Provider>
        </div>

    )
}
export default Login;