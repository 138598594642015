import axios from "axios";
import fileDownload from 'js-file-download';
import React, {useEffect, useState} from "react";

import Patterns from "./Patterns";
import apiUrl from "../../settings/config";
import {getAuthCookie, $, convert_date} from "../../settings/modules";

const AddOrder = () => {
    const [companies, setCompanies] = useState([])

    function sendForm() {
        let isSend = true
        const keys = Object.keys(addOrderForm)

        const defaults = ['description', 'patterns']
        for (let i = 0; i < keys.length; i++) {
            if (defaults.includes(keys[i])) continue;

            const val = $(keys[i]).value
            if (val === '') {
                alert('Вы заполнили не все поля!')
                isSend = false
                break
            }
            addOrderForm[keys[i]] = $(keys[i]).value
        }
        if (!isSend) return

        const patterns = []
        for (let i = 0; i < addOrderForm.patterns.length; i++) {
            if (addOrderForm.patterns[i].count === '' || addOrderForm.patterns[i].number === '' || addOrderForm.patterns[i].code === '') {
                isSend = false
                alert('Вы заполнили не все поля!')
                break
            } else if (patterns.includes(addOrderForm.patterns[i].number)) {
                isSend = false
                alert('Номера шаблонов не должны повторяться!')
                break
            }
            patterns.push(addOrderForm.patterns[i].number)
            addOrderForm.patterns[i].number = parseInt(addOrderForm.patterns[i].number)
            addOrderForm.patterns[i].count = parseInt(addOrderForm.patterns[i].count)

        }

        if (!isSend) return
        let headers = getAuthCookie();
        headers.responseType = 'blob'
        axios.put(apiUrl + '/order', addOrderForm, headers).then((resp) => {
            const company_code = companies.filter(company => company.id = parseInt(addOrderForm.company_id))[0]
            fileDownload(resp.data, `${company_code.code} ${addOrderForm.local_pattern_number} - ${addOrderForm.pattern_number} - Gravur Sieb v ${convert_date(new Date() / 1000)}.docx`)

            alert('Заказ добавлен!');
            Object.keys(addOrderForm).map(param => {
                if (param === 'patterns') return null;
                addOrderForm[param] = '';
                $(param).value = ''
            })

            addOrderForm.patterns = [{
                number: 1,
                count: 1,
                code: '',
            }]
            setAddOrderForm({...addOrderForm})
        })

    }

    function addPattern() {
        if (addOrderForm.patterns.length + 1 > 12) return alert('Максимум может быть только 12 шаблонов');
        addOrderForm.patterns.push({
            number: addOrderForm.patterns.length + 1,
            count: 1,
            code: '',
        })
        setAddOrderForm({...addOrderForm})
    }

    const [addOrderForm, setAddOrderForm] = useState({
        description: '',
        local_pattern_number: '',
        circumference: 640,
        wight_engraving: '',
        wight_overlay: '',
        pattern_number: '',
        time_deadline: '',
        company_id: '',
        patterns: [{
            number: 1,
            count: 1,
            code: '',
        }]
    });


    useEffect(() => {
        axios.get(apiUrl + '/get/companies', getAuthCookie()).then((resp) => {
            setCompanies(resp.data)
        })
    }, [])

    return (
        <div className='container' style={{maxWidth: '900px'}}>
            <h2>Добавить заказ</h2>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3">
                    <label className="form-label h5">Номер заказа:</label>
                    <div className=' input-group mb-2'>
                        <select className=' form-select' id='company_id'>
                            <option value=''>Выберите заказчика</option>
                            {companies.map((company, index) => {
                                return (
                                    <option key={index} value={company.id}>{company.name} ({company.code})</option>
                                )
                            })}
                        </select>
                        <input type="text" id="local_pattern_number" className="form-control"
                               defaultValue={addOrderForm.local_pattern_number} aria-describedby="passwordHelpInline"
                               placeholder='Номер'/>
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label h5">Описание:</label>
                    <textarea id='description' defaultValue={addOrderForm.description} className="form-control"/>
                </div>

                <label className="form-label h5">Параметры вала:</label>
                <div className=' input-group mb-2'>
                    <input defaultValue={addOrderForm.circumference} id='circumference' type="number"
                           placeholder="Объём"
                           className="col-sm-2 p-2 form-control"/>
                    <input defaultValue={addOrderForm.wight_engraving} id='wight_engraving' type="text"
                           placeholder="Ширина гравюры" className="col-sm-2 p-2 form-control"/>
                    <input defaultValue={addOrderForm.wight_overlay} id='wight_overlay' type="number"
                           placeholder="Ширина наложения" className="col-sm-7 p-2 form-control"/>
                    <input defaultValue={addOrderForm.pattern_number} id='pattern_number' type='text'
                           placeholder="Дизайн номер" className="col-sm-11 p-2 form-control"/>
                </div>

                <label className="form-label h5">Сделать до:</label>
                <div className="input-group mb-2" style={{maxWidth: "400px"}}>
                    <input defaultValue={addOrderForm.time_deadline} type='date' id="time_deadline"
                           className="form-control"/>
                </div>

                <div className="row mb-2">
                    <div className='row'>
                        <h6 className="col-sm-4 p-2 m-0"> Номер шаблона </h6>
                        <h6 className="col-sm-4 p-2 m-0"> Кол-во </h6>
                        <h6 className="col-sm-4 p-2 m-0"> Мешность</h6>
                    </div>
                    <Patterns addOrderForm={addOrderForm} setAddOrderForm={setAddOrderForm}/>
                </div>
                <button className="btn btn-primary" onClick={() => addPattern()}>Добавить шаблон</button>
                <button className="btn btn-success mx-3" onClick={() => sendForm()}>Добавить заказ</button>
            </form>
        </div>
    )
}
export default AddOrder;