import React from 'react';

const Filter = ({companies, filter, setFilter}) => {

    function sortHandle({target:{value, checked}}) {
        console.log(filter)
        setFilter({
            ...filter,
            [value]: checked
        })
    }
    function filterHandle({target:{value, name, checked}}) {
        console.log(filter)
        console.log(value,name,checked)
        if (name==='status') {
            let array = [...filter.statuses]

            if (checked) {
                array.push(value)
            } else {
                array = array.filter(status => status !== value)
            }

            setFilter({
                ...filter,
                statuses: array
            })

        } else {
            setFilter({
                ...filter,
                [name]:value
            })
        }
    }

    return (
        <nav id="sidebarMenu" className="col-md-4 col-lg-2 d-md-block sidebar collapse">
            <div className="position-sticky pt-3">
                <h3 className='text-center'>Фильтры</h3>
                <div>
                    <div className="mb-3">
                        <label className="form-label">Поиск по номеру</label>
                        <input type="number" defaultValue={filter.number} onBlur={filterHandle} name='number' className="form-control"/>

                    </div>
                    <div className="mb-3">
                        <label className="form-label">По заказчику</label>
                        <select name="code" defaultValue={filter.code} onBlur={filterHandle} className='form-select'>
                            <option value="">Все</option>
                            {companies.map((company, index) => <option key={index} value={company.code}>{company.name} ({company.code})</option>)}
                        </select>

                    </div>
                    <div className='mb-3'>
                        <label className="form-label">По статусу</label>

                        <div className="list-group">

                            <label className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox" defaultChecked={filter.statuses.includes('new')} onChange={filterHandle} value="new" name='status'/>
                                Новые
                            </label>
                            <label className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox" defaultChecked={filter.statuses.includes('active')} onChange={filterHandle} value="active" name='status'/>
                                В работе
                            </label>
                            <label className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox" defaultChecked={filter.statuses.includes('closed')} onChange={filterHandle} value="closed" name='status'/>
                                Готовы
                            </label>
                        </div>

                    </div>
                    <div className="mb-3">
                        <label className="form-label">Сделать до</label>
                        <input type="date" name='deadline' onBlur={filterHandle} className="form-control"/>
                    </div>
                </div>
                <h3>Сортировать</h3>
                {/*<div className="form-check">*/}
                {/*    <input className="form-check-input" type="checkbox" defaultChecked={filter.sort_by_deadline} onChange={sortHandle} value="sort_by_deadline"/>*/}
                {/*    <label className="form-check-label" >*/}
                {/*        Сначала срочные*/}
                {/*    </label>*/}
                {/*</div>*/}
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" defaultChecked={filter.sort_by_new} onChange={sortHandle} value="sort_by_new"/>
                    <label className="form-check-label" >
                        Сначала сначала новые
                    </label>
                </div>
            </div>
        </nav>
    )
}
export default Filter;