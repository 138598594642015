import React from "react";
import {cfg_statuses,cfg_color} from '../../settings/config';
import {orderStatus} from "../../settings/modules";
import {NavLink} from "react-router-dom";

const Order = ({order}) => {
    const status = orderStatus(order)
    return (<div className={"card my-2"}>
        <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">Заказ №{order.code} {order.order_number}</h5>
            <h6 className='p-2' style={{ backgroundColor: cfg_color[status], color: '#fff', borderRadius: '10px' }}>{cfg_statuses[status]}</h6>
        </div>
        <div className="card-body">

            {order.description && <h6 className="card-text my-3">Описание: {order.description}</h6>}
            <hr/>
            <NavLink style={{textDecoration: "none", color: '#000', width: '100%'}}
                     role="button"
                     className=" h5"
                     to={`/order/${order.id}`}>Подробная информация</NavLink>
            <hr/>
            <div className="" id={`pattern-${order.id}`}>
                <div className='my-4'>
                    {order.is_repit ? (<span className='h4 p-1 '
                                             style={{ backgroundColor: '#212529', color: '#fff', borderRadius: '10px' }}>
                        Повтор
                    </span>) : ''}
                    <table >
                        <tbody>
                            <tr>
                                <th>Всего шаблонов:</th>
                                <th style={{paddingLeft:'1rem'}}>{order.patterns_count}</th>
                            </tr>
                            <tr>
                                <th>Шаблонов в работе:</th>
                                <th style={{paddingLeft:'1rem'}}>{order.active_count}</th>
                            </tr>
                            <tr>
                                <th>Шаблонов готово:</th>
                                <th style={{paddingLeft:'1rem'}}>{order.closed_count}</th>
                            </tr>
                            <tr>
                                <th>Сделать до:</th>
                                <th style={{paddingLeft:'1rem'}}>{order.time_deadline}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <p>Создал <b>{order.author}</b> в <b>{order.time_start}</b></p>
        </div>
    </div>)
}

export default Order;