import React, {useState,useContext} from "react";
import {cfg_statuses, cfg_styles, cfg_titles,statuses} from "../../../settings/config";
import EditButton from "../../Page/EditButton";
import {authContext} from "../../Context"

const Procedure = ({procedure, setNextStatus, procedure_list, setOrderInfo}) => {
    const [isEdit, setEdit] = useState(procedure.id === undefined)
    const {User} = useContext(authContext);

    function editHandle() {
        setEdit(prev => !prev)
    }

    function editShafts({target: {name, value}}) {
        setOrderInfo(
            shaft_info => { return {...shaft_info,
                'procedures': shaft_info.procedures.map((shaft) => {
                    if (procedure.number === shaft.number) {
                        if (name==='type'){
                            return {
                                ...shaft,
                                'is_edit':true,
                                'type':procedure_list.filter(obj => obj.id === parseInt(value))[0]
                            }
                        }
                        return {
                            ...shaft,
                            'is_edit':true,
                            [name]: value
                        }
                    }
                    return shaft
                })
            }}
        )
    }

    if (isEdit) {
        return (<tr className={`table-${cfg_styles[procedure.status]}`}>
            <td>{procedure.number}</td>
            <td>
                <select className='form-select' name='type' onChange={editShafts} defaultValue={procedure.type?.id}>
                    {
                        procedure_list.map(obj => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                    }
                </select>
            </td>
            <td>{procedure.worker_active}</td>
            <td>{procedure.time_active}</td>
            <td>{procedure.closed_worker}</td>
            <td>{procedure.time_close}</td>
            <td>
                <select className='form-select' onChange={editShafts} name="status" defaultValue={procedure.status}>
                    {statuses.map((status, index) => {
                        return <option key={index} value={status}>{cfg_statuses[status]}</option>
                    })}
                </select>
            </td>
            <td>
                <button className='btn btn-primary ' disabled={['closed','canceled'].includes(procedure.status)}
                        onClick={() => setNextStatus(procedure.id)}>{cfg_titles[procedure.status]}</button>
            </td>
            <td>
                {[3,4].includes(User.role_id) ? '' : <EditButton editHandle={editHandle}/>}

            </td>
        </tr>)
    }

    return (
        <tr className={`table-${cfg_styles[procedure.status]}`}>
            <td>{procedure.number}</td>
            <td>{procedure.type.name}</td>
            <td>{procedure.worker_active}</td>
            <td>{procedure.time_active}</td>
            <td>{procedure.closed_worker}</td>
            <td>{procedure.time_close}</td>
            <td>{cfg_statuses[procedure.status]}</td>
            <td>
                <button className='btn btn-primary ' disabled={['closed','canceled'].includes(procedure.status) || User.role_id === 4}
                        onClick={() => setNextStatus(procedure.id)}>{cfg_titles[procedure.status]}</button>
            </td>
            <td>
                {[3,4].includes(User.role_id) ? '' : <EditButton editHandle={editHandle}/>}
            </td>
        </tr>
    )
}
export default Procedure;