import React, {useEffect, useState,useContext} from 'react';
import axios from "axios";
import {getAuthCookie} from "../../../settings/modules";
import {useParams} from "react-router-dom";
import apiUrl from "../../../settings/config";
import EditButton from "../../Page/EditButton";
import Procedures from "./Procedures";
import {authContext} from '../../Context'

const EmbossingShaft = () => {
    const shaft_id = useParams().id;

    const [companies, setCompanies] = useState([])
    const [order_info, setOrderInfo] = useState({})
    const [isEdit, setEdit] = useState(false)
    const [procedure_list, setProcedures] = useState([])
    const {User} = useContext(authContext);

    function submitChanges() {
        axios.post(apiUrl + '/embossing/', order_info, getAuthCookie()).then(resp => console.log(resp.data))
    }

    useEffect(() => {

        axios.get(apiUrl + '/embossing/' + shaft_id, getAuthCookie())
            .then((resp) => setOrderInfo(resp.data))

        axios.get(apiUrl + '/get/procedures', getAuthCookie())
            .then((resp) => setProcedures(resp.data))

        axios.get(apiUrl + '/get/companies', getAuthCookie()).then((resp) => {
            setCompanies(resp.data)
        })
    }, [])

    function editHandle({target: {name, value}}) {
        if (name === 'company') {
            setOrderInfo({
                ...order_info,
                'is_edit': true,
                company: companies.filter(obj => obj.id === parseInt(value))[0]
            })
        } else {
            setOrderInfo({...order_info, [name]: value, 'is_edit': true})
        }
    }

    const edit_pattern = (isEdit) => {
        if (isEdit) {
            return (
                <div className='d-flex justify-content-start'>
                    <h2>Информация о заказе </h2>
                    <div className='mx-3 d-flex justify-content-start'>
                        <select className='form-select' name="company" onChange={editHandle}
                                defaultValue={order_info.company.id}>
                            {companies.map((company, index) => <option key={index}
                                                                       value={company.id}>{company.name}</option>)}
                        </select>
                        <input type="text" className='form-control' name='number' onBlur={editHandle}
                               defaultValue={order_info.number}/>
                    </div>
                </div>
            )
        }
        return (
            <div>
                Информация о заказе <span className='px-1' style={{
                backgroundColor: '#212529',
                color: '#fff',
                borderRadius: '10px'
            }}>{order_info.company.code} {order_info.number} </span>:
            </div>

        )
    }

    function addShaft() {
        setOrderInfo({
            ...order_info,
            procedures: [...order_info.procedures, {
                status: 'new',
                number: order_info.procedures.length + 1,
                type: procedure_list[0]
            }]
        })
    }

    return (
        <div>
            {
                Object.keys(order_info).length === 0 ? '' : (
                    <div>
                        <div className='mt-4 h2 d-flex justify-content-between'>
                            {edit_pattern(isEdit)}
                            { [3,4].includes(User.role_id) ? '' : <div className='mx-1'>

                                <EditButton editHandle={() => setEdit(prev => !prev)}/>
                                <button className='ms-3 btn btn-outline-primary' onClick={addShaft}>Добавить операцию
                                </button>
                            </div>}


                        </div>
                        <div>
                            <hr/>

                            {!isEdit ?
                                <span className='h6'>Описание: {order_info.description}</span> :
                                <div>Описание: <textarea className='h5 form-control' name='description'
                                          defaultValue={order_info.description} onBlur={editHandle}/>
                                </div>
                            }
                            <hr/>
                        </div>
                        <div>
                            {!isEdit ?
                                <span className='h6'>Сделать до: {order_info.time_deadline}</span> :
                                (<div>Сделать до: <input className='form-control' type='datetime-local' onBlur={editHandle} name='time_deadline' defaultValue={order_info.time_deadline_us}/></div>)
                            }
                        </div>
                        <hr/>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Операция</th>
                                <th>Взял</th>
                                <th>Взято в работу</th>
                                <th>Завершил</th>
                                <th>Завершено</th>
                                <th>Статус</th>
                                <th></th>
                            </tr>
                            </thead>
                            <Procedures procedure_list={procedure_list} procedures={order_info.procedures}
                                        setOrderInfo={setOrderInfo} order_info={order_info}/>
                        </table>
                        {[3,4].includes(User.role_id) ? '' :  <button className='btn-outline-success btn' onClick={submitChanges}>Сохранить изменения
                        </button>}

                    </div>)}
        </div>
    )
}
export default EmbossingShaft;