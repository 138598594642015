import React, {useEffect, useState} from 'react';
import Card from './EmbossingCard'
import axios from "axios";
import {EmbossingCardStatus, getAuthCookie} from "../../../settings/modules";
import apiUrl from "../../../settings/config";
import Filter from "./Filter";

const EmbossingShafts = () => {
    const [data, setData] = useState([])
    const [companies, setCompanies] = useState([])
    const [filter, setFilter] = useState({
        number:'',
        code:'',
        deadline:'',
        statuses:['new','active'],
        sort_by_new: true,
        sort_by_deadline: true
    })

    useEffect(()=>{
        axios.get(apiUrl+'/embossing/', getAuthCookie())
            .then((resp)=>setData(resp.data))

        axios.get(apiUrl + '/get/companies', getAuthCookie()).then((resp) => {
            setCompanies(resp.data)
        })
    },[])
    function sortHandle(x, y) {
        return filter.sort_by_new ? x.id - y.id : y.id - x.id
    }
    function filterHandler(filter, card) {
        const d = card.time_deadline?.split('.')

        if (!d && filter.deadline !== '') return false
        if (!card.number.toString().includes(filter.number)) return false
        if (card.code !== filter.code && filter.code !== '') return false
        if (!filter.statuses.includes(EmbossingCardStatus(card))) return false
        if (d && !new Date(filter.deadline) > new Date(d[2], d[1]-1, d[0]) && filter.deadline !== '') return false

        return true
    }

    return (
        <div className='row'>

            <div className='col-md-8 ms-sm-auto col-lg-10 px-md-4'>
                <div className='align-items-center pt-3 pb-2 mb-3 border-bottom'>
                    <h1 className='text-center'>Валы на тиснение</h1>
                    {data.sort(sortHandle).map((shaft) => {
                        if (filterHandler(filter, shaft)) {
                            return <Card key={shaft.id} shaft={shaft}/>
                        }

                    } )}
                </div>
            </div>
            <Filter companies={companies} filter={filter} setFilter={setFilter}/>
        </div>
    )
}
export default EmbossingShafts;