import Pattern from "./Pattern";
import React from 'react';

const Patterns = ({addOrderForm, setAddOrderForm}) => {
    return (
        <div>
            {
                addOrderForm.patterns.map((pattern, index) => {
                    return <Pattern addOrderForm={addOrderForm} setAddOrderForm={setAddOrderForm} key={index} index={index} />
                })
            }
        </div>
    )
}
export default Patterns;