import axios from "axios";
import React, { useEffect, useState} from 'react';

import Order from './Order';

import apiUrl from "../../settings/config";
import {getAuthCookie} from "../../settings/modules";

const Orders = () => {
    console.log('Tasks render')
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        axios.get(apiUrl + '/get/tasks', getAuthCookie()).then((resp) => {
            if (orders !== resp.data) setOrders(resp.data)
        });

    }, []);

    return (
        <div className='m-auto' style={{maxWidth:'900px'}}>
            <h3>Список заказов:</h3>

            {orders.map((order, index) => (
                <Order key={index} order={order}/>
            ))}
        </div>
    )
}
export default Orders;